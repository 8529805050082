<template>
  <tp-modal max-width="700px" name="modal-form-home-banner" @open="closeModal">
    <template slot-scope="props">
      <v-form ref="formHomeBanner" lazy-validation>
        <v-card>
          <v-toolbar
            class="px-5 pb-0 align-start"
            extension-height="40px"
            height="56x"
            style="position: sticky; top: 0; left: 0; right: 0; z-index: 1"
          >
            <v-toolbar-title class="font-weight-bold">Banner</v-toolbar-title>

            <v-spacer></v-spacer>

            <div class="flex align-center justify-end">
              <v-btn color="grey darken-1" text @click="closeModal"> Hủy</v-btn>
              <v-btn
                v-if="!props.payload.id"
                :loading="statusRequest.value === 'loading-updateHomeSetting'"
                class="cyan lighten-5 rounded-lg ml-4"
                color="primary"
                text
                @click="updateOrCreateHomeBanner(props.payload)"
              >
                Tạo
              </v-btn>
              <v-btn
                v-else
                :loading="statusRequest.value === 'loading-updateHomeSetting'"
                class="cyan lighten-5 rounded-lg ml-4"
                color="primary"
                text
                @click="updateOrCreateHomeBanner(props.payload)"
              >
                Lưu
              </v-btn>
            </div>
          </v-toolbar>

          <div class="text-body-1 px-5 mt-5">
            <tp-text-field
              v-model="props.payload.title"
              class="mb-2"
              hide-details="auto"
              label="Tiêu đề"
              placeholder="Nhập tiêu đề ngắn gọn"
              validate="required"
            ></tp-text-field>

            <v-checkbox
              v-model="props.payload.is_show_description"
              class="mt-0 mb-3"
              hide-details
              label="Hiển thị description"
            ></v-checkbox>

            <tp-text-field
              v-if="props.payload.is_show_description"
              v-model="props.payload.description"
              class="mb-2"
              hide-details="auto"
              label="Mô tả"
              placeholder="Nhập mô tả ngắn gọn"
              validate="required"
            ></tp-text-field>

            <v-checkbox
              v-model="props.payload.is_show_cta_button"
              class="mt-0 mb-3"
              hide-details
              label="Hiển thị CTA button"
            ></v-checkbox>

            <input-color-picker
              v-if="props.payload.is_show_cta_button"
              v-model="props.payload.color"
              :rules="[v => !!v || 'Không được bỏ trống']"
              class="mb-6"
              hide-details="auto"
              label="Màu text"
            ></input-color-picker>

            <input-color-picker
              v-if="props.payload.is_show_cta_button"
              v-model="props.payload.button_color"
              :rules="[v => !!v || 'Không được bỏ trống']"
              class="mb-6"
              hide-details="auto"
              label="Màu button"
            ></input-color-picker>

            <input-color-picker
              v-model="props.payload.background_color"
              :rules="[v => !!v || 'Không được bỏ trống']"
              class="mb-6"
              hide-details="auto"
              label="Màu background"
            ></input-color-picker>

            <tp-text-field
              v-if="props.payload.is_show_cta_button"
              v-model="props.payload.button_text"
              class="mb-6"
              hide-details="auto"
              label="CTA button text"
              placeholder="Nhập nội dung"
              validate="required"
            ></tp-text-field>

            <tp-text-field
              v-if="props.payload.is_show_cta_button"
              v-model="props.payload.button_url"
              class="mb-6"
              dense
              hide-details="auto"
              label="Đường dẫn khi bấm vào button"
              outlined
              placeholder="Nhập nội dung"
              prepend-inner-icon="mdi-link"
              validate="required|url"
            ></tp-text-field>

            <tp-text-field
              v-model="props.payload.banner_url"
              class="mb-2"
              dense
              hide-details="auto"
              label="Đường dẫn khi bấm vào banner"
              outlined
              placeholder="Nhập nội dung"
              prepend-inner-icon="mdi-link"
              validate="required|url"
            ></tp-text-field>

            <div>
              <div class="mb-2">Cover</div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                <div>
                  Không yêu cầu kích thước, vui lòng kiểm tra kích thước cho
                  từng website.
                </div>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :maxFileSize="500 * 1024"
                :src.sync="props.payload.cover"
                allowCompress
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
                required
                style="height: 213px; max-width: 800px; width: 100%"
              ></tp-input-image>
            </div>

            <div class="mt-6">
              <div class="mb-2">Cover mobile</div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                <div>
                  Không yêu cầu kích thước, vui lòng kiểm tra kích thước cho
                  từng website.
                </div>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :maxFileSize="500 * 1024"
                :src.sync="props.payload.cover_mobile"
                allowCompress
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
                style="height: 108px; max-width: 320px; width: 100%"
              ></tp-input-image>
            </div>

            <div class="mt-6">
              <div class="mb-2">Thumbnail</div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                Kích thước ảnh yêu cầu
                <span class="font-weight-bold">là 1200 x 630 px</span>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :maxFileSize="500 * 1024"
                :src.sync="props.payload.thumbnail"
                allowCompress
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
                required
                style="height: 420px; max-width: 800px; width: 100%"
              ></tp-input-image>
            </div>
          </div>
        </v-card>
      </v-form>
    </template> </tp-modal
></template>

<script>
import InputColorPicker from "./InputColorPicker.vue";
import MediaService from "@/core/service/media.service";
import { toFormData } from "@vt7/utils";
import { showModalAlertError } from "@/core/composables";

export default {
  components: {
    InputColorPicker
  },

  data() {
    return {};
  },

  computed: {
    statusRequest() {
      return this.$store.getters["HOME_SETTING/statusRequest"];
    }
  },

  methods: {
    async updateOrCreateHomeBanner(banner) {
      const isValid = this.$refs.formHomeBanner.validate();

      if (!isValid) return;

      // Check thumbnail changes
      if (banner.thumbnail instanceof File) {
        try {
          const { data } = await MediaService.uploadImage(
            toFormData({
              file: banner.thumbnail,
              folder: "core/categories"
            })
          );

          banner.thumbnail = data;
        } catch (error) {
          showModalAlertError(this, {
            title: "Lỗi tải lên ảnh thumbnail",
            message: error
          });

          return;
        }
      }

      // Check cover changes
      if (banner.cover instanceof File) {
        try {
          const { data } = await MediaService.uploadImage(
            toFormData({
              file: banner.cover,
              folder: "core/categories"
            })
          );

          banner.cover = data;
        } catch (error) {
          showModalAlertError(this, {
            title: "Lỗi tải lên ảnh cover",
            message: error
          });

          return;
        }
      }

      // Check cover mobile changes
      if (banner.cover_mobile instanceof File) {
        try {
          const { data } = await MediaService.uploadImage(
            toFormData({
              file: banner.cover_mobile,
              folder: "core/categories"
            })
          );

          banner.cover_mobile = data;
        } catch (error) {
          showModalAlertError(this, {
            title: "Lỗi tải lên ảnh cover mobile",
            message: error
          });
          return;
        }
      }

      if (banner.id) {
        this.$emit("updateHomeBanner", banner);
        return;
      } else {
        this.$emit("createHomeBanner", banner);
      }
    },

    closeModal() {
      this.$refs.formHomeBanner?.resetValidation();
      this.$modal.hide({ name: "modal-form-home-banner" });
    }
  },

  watch: {
    "statusRequest.value": {
      handler(value) {
        if (value === "success-updateHomeSetting") {
          this.closeModal();
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
