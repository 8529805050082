<template>
  <div class="pa-4">
    <v-btn color="primary" outlined class="mb-4" @click="openCreateBannerModal">
      <v-icon left>mdi-plus</v-icon>
      Thêm banner
    </v-btn>

    <table class="table home-banner-table">
      <thead class="thead-dark">
        <th width="50"></th>
        <th>Banner</th>
        <th>Tên Banner</th>
        <th>Url</th>
        <th>Hành động</th>
      </thead>

      <draggable
        v-model="homeSettingBanner.items"
        tag="tbody"
        :key="key"
        @change="sortList(homeSettingBanner.items)"
        @end="drag = false"
        @start="drag = true"
      >
        <tr v-for="item in homeSettingBanner.items" :key="item.id">
          <td>
            <div>
              <svg
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
                width="20"
              >
                <path
                  d="M5.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 4.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm1.5 3a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0ZM10.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3ZM12 8a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Zm-1.5 6a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </td>

          <td>
            <v-img
              :aspect-ratio="16 / 9"
              :src="item.cover"
              height="50"
              lazy-src="https://picsum.photos/id/11/100/60"
              width="100"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </td>

          <td>
            <div class="home-banner-treeselect">
              {{ item.title }}
            </div>
          </td>

          <td>
            <div>
              <a :href="item.url" target="_blank">{{ item.banner_url }}</a>
            </div>
          </td>

          <td>
            <div class="d-flex align-center justify-center">
              <div @click="deleteBanner(item)">
                <v-btn color="error" icon>
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
              <div @click="openUpdateBannerModal(item)">
                <v-btn color="primary" icon>
                  <v-icon>mdi-border-color</v-icon>
                </v-btn>
              </div>
            </div>
          </td>
        </tr>
      </draggable>
    </table>

    <ModalFormHomeBanner
      @updateHomeBanner="handleUpdateHomeBanner($event)"
      @createHomeBanner="handleCreateHomeBanner($event)"
    ></ModalFormHomeBanner>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ModalFormHomeBanner from "./ModalFormHomeBanner.vue";

export default {
  components: {
    draggable,
    ModalFormHomeBanner
  },
  data() {
    return {
      color: "#1976D2FF",
      key: 0
    };
  },
  computed: {
    homeSettingBanner() {
      const { BANNER } = this.$store.getters["HOME_SETTING/homeSetting"];
      return BANNER;
    }
  },
  methods: {
    sortList() {
      this.$emit("updateHomeSetting");
    },

    deleteBanner(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "Xóa banner?",
          message:
            "Bạn có chắc chắn muốn xóa banner này? Bạn có thể chỉnh sửa nếu chỉ cần thay đổi gì đó.",
          confirmBtn: {
            text: "Xóa",
            color: "error",
            onClickHandler: () => {
              this.homeSettingBanner.items = this.homeSettingBanner.items.filter(
                banner => banner.id !== item.id
              );

              this.$emit("updateHomeSetting");
            }
          }
        }
      });
    },

    handleUpdateHomeBanner(newBanner) {
      const index = this.homeSettingBanner.items.findIndex(
        banner => banner.id === newBanner.id
      );

      if (index !== -1) {
        this.homeSettingBanner.items[index] = newBanner;
      }

      this.key++;

      this.$emit("updateHomeSetting");
    },

    handleCreateHomeBanner(newBanner) {
      this.homeSettingBanner.items.push(newBanner);

      this.key++;

      this.$emit("updateHomeSetting");
    },

    openCreateBannerModal() {
      this.$modal.show({
        name: "modal-form-home-banner",
        payload: {}
      });
    },

    openUpdateBannerModal(item) {
      this.$modal.show({
        name: "modal-form-home-banner",
        payload: {
          ...item
        }
      });
    }
  }
};
</script>

<style lang="scss">
.home-banner-table {
  width: 100%;
  border-collapse: separate !important;
}

.home-banner-table th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.home-banner-treeselect {
  width: 300px;
}
</style>
